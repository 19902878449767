<template>
    <div class="menu">
        <div class="relative pb-5 bg-white rounded-lg shadow-lg sub-menu">
            <router-link
                v-if="checkPermission('top_banners')"
                class="rounded-lg menu-item active"
                :to="{ name: 'top_banners' }"
            >
                置頂 Banner
            </router-link>
            <router-link
                v-if="checkPermission('area_banners')"
                class="rounded-lg menu-item active"
                :to="{ name: 'area_banners' }"
            >
                區塊 Banner
            </router-link>
            <router-link
                v-if="checkPermission('app_banners')"
                class="rounded-lg menu-item active"
                :to="{ name: 'app_banners' }"
            >
                APP Banner
            </router-link>
            <router-link
                v-if="checkPermission('promote_img')"
                class="rounded-lg menu-item active"
                :to="{ name: 'promote_img' }"
            >
                推廣大圖
            </router-link>
            <router-link
                v-if="checkPermission('newcomer_list')"
                class="rounded-lg menu-item active"
                :to="{ name: 'newcomer_list' }"
            >
                猜你喜歡
            </router-link>
            <router-link
                v-if="checkPermission('footer_setting')"
                class="rounded-lg menu-item active"
                :to="{ name: 'footer_setting' }"
            >
                Footer 設定
            </router-link>
            <router-link
                v-if="checkPermission('user_experience')"
                class="rounded-lg menu-item active"
                :to="{ name: 'user_experience' }"
            >
                形象首頁 設定
            </router-link>
            <router-link
                v-if="checkPermission('index_order_bg')"
                class="rounded-lg menu-item active"
                :to="{ name: 'index_order_bg' }"
            >
                首頁即刻快閃背景圖
            </router-link>
            <div
                style="
                    width: 100%;
                    border-bottom: 1px solid #ccc;
                    margin: 10px 0;
                "
                class="line"
            ></div>
            <router-link
                v-if="checkPermission('lastest_news')"
                class="rounded-lg menu-item active"
                :to="{ name: 'lastest_news' }"
            >
                服務商最新消息
            </router-link>
            <router-link
                v-if="checkPermission('fighting_rules')"
                class="rounded-lg menu-item active"
                :to="{ name: 'fighting_rules' }"
            >
                服務商教戰守則
            </router-link>
            <div
                style="
                    width: 100%;
                    border-bottom: 1px solid #ccc;
                    margin: 10px 0;
                "
                class="line"
            ></div>
            <router-link
                v-if="checkPermission('member_news')"
                class="rounded-lg menu-item active"
                :to="{ name: 'member_news' }"
            >
                會員最新消息
            </router-link>
            <router-link
                v-if="checkPermission('member_news')"
                class="rounded-lg menu-item active"
                :to="{ name: 'market_set' }"
            >
                會員行銷資訊
            </router-link>
        </div>
        <button
            v-if="!$isEmpty(btnText)"
            v-permission="['create']"
            class="w-full btn-add orange-btn-800-lg"
            @click="clickBtn"
        >
            {{ btnText }}
        </button>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "SideBar",
    props: {
        btnText: {
            type: String,
            default: "+ 新增置頂 Banner",
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems
                ? this.$route.meta.params.sideItems
                : [];
        },
    },
    methods: {
        clickBtn() {
            this.$emit("clickBtn");
        },
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    margin-right: 10px;
    .sub-menu {
        min-width: 248px;
        background: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 10px 15px;
        .menu-item {
            width: 100%;
            height: 40px;
            text-align: left;
            font-size: 14px;
            padding: 20px 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 10px 0;
            &.router-link-active {
                background: #ff583314;
            }
        }
    }
    .btn-add {
        margin-top: 12px;
    }
}
</style>
